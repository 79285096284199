import React, { Component } from 'react'
import { connect } from 'react-redux'
import { GlobalState } from '../../reducers'
import { Link } from 'react-router-dom'
import { history, AppDispatch } from '../../store'
import { TitleContainer } from '../../components/Layout'
import FeeSummaryTable from './FeeSummaryTable'
import FeeFootnotes from './FeeFootnotes'
import iconArrow from '../../assets/images/arrow_right.png'
import iconPrint from '../../assets/images/print.png'
import { FeeScheduleObj } from '../../objects/investments'
import { getFeeSchedule } from '../../actions/investments.action'
import ucLogo from '../../assets/images/logos/United-Capital-Logo-300x100.png'
import { dateFormatStrategy } from '../../helpers'

export interface FeeScheduleProps {
  isFinlifeInstitution: boolean
  feeSchedule: FeeScheduleObj[]
  strategyAsOfDate: string
  dispatch: AppDispatch
}
export class FeeSchedule extends Component<FeeScheduleProps> {
  public componentDidMount() {
    const { isFinlifeInstitution, dispatch, feeSchedule } = this.props
    if (isFinlifeInstitution) {
      history.push('/')
    }
    if (!(feeSchedule.length > 0)) {
      dispatch(getFeeSchedule())
    }
  }

  public printPdf = () => {
    window.print()
  }

  public renderPdfHeader = () => {
    return (
      <div className='feesum-pdf-header'>
        <img className='feesum-pdf-header__logo' src={ucLogo} />
        <p className='feesum-pdf-header__text'>
          PFM Portfolio Manager Fee Summary
        </p>
      </div>
    )
  }

  public render() {
    const { isFinlifeInstitution, feeSchedule, strategyAsOfDate } = this.props
    const feeDataWithManagerOrOperationFees = feeSchedule?.filter(
      (feeListItem) =>
        feeListItem.managerFee > 0 || feeListItem.operationsFee > 0
    )
    const asOfDate = dateFormatStrategy(strategyAsOfDate)
    if (this.props && !isFinlifeInstitution) {
      return (
        <div>
          <div className='feesum-table__btn-w'>
            <Link
              className='feesum-table__back-btn'
              to={{
                pathname: '/disclosures',
                state: { section: 'investment', prevPath: '/investments' }
              }}>
              <img src={iconArrow} alt='arrow' />
              Back
            </Link>
            {feeDataWithManagerOrOperationFees?.length > 0 && (
              <span className='feesum-table__print-btn' onClick={this.printPdf}>
                <img src={iconPrint} alt='print' />
                Print
              </span>
            )}
          </div>
          {this.renderPdfHeader()}
          <TitleContainer title='United Capital Portfolio Manager Fee Summary' />
          <p>
            {`Below is a list of the strategies your advisor may recommend that
            you select or has discretion to select for your account (subject to
            eligibility), together with manager and certain fee information for
            each strategy, as of ${asOfDate || ''}. The manager fees and
            certain other operational fees listed below are in addition to any
            United Capital advisory fee, custodial trading fees and/or fees embedded in
            any mutual funds or ETFS that may apply. Manager fees listed for
            managers affiliated with United Capital may not apply to individual
            retirement accounts, qualified retirement plan accounts and
            Coverdell Education Savings Accounts. For more information, please
            contact your advisor.`}
          </p>
          <p>
            Note: There are certain instances where implementation model
            definitions are provided by third party sub-managers or sub-advisors
            identified below or constructs and manages portfolios for clients on
            an individual basis, including using other advisors within United
            Capital from time to time. "Multi-Manager" indicates strategies for
            which United Capital has discretion to implement model portfolios
            provided by more than one third-party sub-manager or sub-advisor. To
            the extent that a manager fee is listed without "United Capital" in
            the "Portfolio Manager" Column, such fees are the third-party
            sub-manager or sub-advisor fees.
          </p>
          {feeDataWithManagerOrOperationFees?.length > 0 && (
            <>
              <FeeSummaryTable feeData={feeDataWithManagerOrOperationFees} />
              <FeeFootnotes feeData={feeDataWithManagerOrOperationFees} />
            </>
          )}
        </div>
      )
    } else {
      return null
    }
  }
}

export default connect((store: GlobalState, dispatch: AppDispatch) => {
  return {
    dispatch,
    strategyAsOfDate: store.investments.strategyAsOfDate,
    isFinlifeInstitution: store.user.isFinlifeInstitution,
    feeSchedule: store.investments.feeSchedule
  }
})(FeeSchedule)
